var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'الطلبات <strong class="orders-num btn btn-sm text-white bg-danger dnone"></span>',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'الأجهزة',
    icon: 'HardDriveIcon',
    children: [
      {
        title: 'استعراض الأجهزة',
        route: 'devices',
      },
      {
        title: 'اضافة جهاز جديد',
        route: 'devices-add',
      }
    ],
  },
  {
    title: 'المشرفين',
    icon: 'UsersIcon',
    children: [
      {
        title: 'استعراض المشرفين',
        route: 'admins',
      },
      {
        title: 'اضافة مشرف جديد',
        route: 'admins-add',
      }
    ],
  },
  {
    title: 'الأماكن',
    icon: 'MapIcon',
    route: 'places',
  },
  {
    title: 'تسجيل الخروج',
    route: 'logout',
    icon: 'LogOutIcon',
  },
], arr2 = [];

var user = JSON.parse(localStorage.getItem('user'))

arr.forEach(function (e) {
  if (user) {
    if (user.admin) {
      arr2.push(e)
    } else {
      if (e.icon == 'HomeIcon' || e.icon == 'ShoppingCartIcon' || e.icon == 'LogOutIcon') {
        arr2.push(e)
      }
    }
  }
})


export default arr2
